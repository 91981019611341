import { ChangeEvent } from "react"
import { Checkbox, Icon, Spinner } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import cx from "classnames"

import { useTransaction, useTransactionItems } from "../api"
import Link from "next/link"

type MercurySigningToggleProps = {
  isChecked?: boolean
  isDisabled?: boolean
  transactionId: string
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void
  theme?: string
}

export default function MercurySigningToggle({
  isChecked,
  isDisabled: isDisabledProp,
  onChange = () => null,
  transactionId,
  theme,
}: MercurySigningToggleProps) {
  let transactionQuery = useTransaction(transactionId, {
    enabled: Boolean(transactionId) && !isDisabledProp,
  })
  let itemsQuery = useTransactionItems(transactionId, {
    enabled: Boolean(transactionId) && !isDisabledProp,
  })

  let isLoading = transactionQuery.isLoading || itemsQuery.isLoading

  if (isLoading) {
    return (
      <div className="flex items-center">
        <Spinner size={16} />
        <span className="ml-2">Mercury-compliant transaction</span>
      </div>
    )
  }

  let deededItems = itemsQuery.data?.filter((item) => item.isDeed) || []

  let isDisabled =
    isDisabledProp ||
    Boolean(transactionQuery.data?.expiredAt) ||
    (transactionId && deededItems.length > 0)

  let checkboxClasses = cx("mb-4", {
    "reverse-checkbox": theme === "light",
  })

  return (
    <Checkbox
      id="isDeedTransaction"
      className={checkboxClasses}
      disabled={isDisabled}
      label={
        isDisabled ? (
          <Tooltip2
            content={
              <div className="w-88">
                <p>
                  This transaction has at least one document marked as a deed.
                  If you want to unmark this transaction as being
                  Mercury-compliant, please unmark the following documents that
                  have been marked as deeds.
                </p>
                <ul className="list-disc py-2 pl-4">
                  {deededItems.map((item) => (
                    <li key={item.id} className="pb-2">
                      <Link
                        href={`/transaction/${transactionId}/item/${item.id}`}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            }
            position="right"
            interactionKind="hover"
            hoverOpenDelay={800}
          >
            <>
              Mercury-compliant transaction
              <Icon icon="info-sign" className="ml-2" />
            </>
          </Tooltip2>
        ) : (
          "Mercury-compliant transaction"
        )
      }
      checked={isChecked}
      onChange={onChange}
    />
  )
}
