import * as React from "react"

import Link from "next/link"

import { apiClient } from "lib/api"
import { QueryKey } from "react-query"

type PrefetchLinkProps = {
  children: React.ReactElement
  href: string
  queries: {
    key: QueryKey
    fn: () => void
    staleTime?: number
  }[]
  staleTime?: number
}

export default function PrefetchLink({
  href,
  children,
  queries = [],
  staleTime = 10000,
}: PrefetchLinkProps) {
  function prefetch(evt: MouseEvent) {
    evt.stopPropagation()
    queries.forEach((query) =>
      apiClient.prefetchQuery(query.key, query.fn, {
        // use the query's staleTime if it's provided, otherwise use the default
        staleTime: query.staleTime || staleTime,
      })
    )
  }

  let anchorCopy = React.cloneElement(React.Children.only(children), {
    onMouseEnter: prefetch,
  })

  return (
    <Link className="hover:no-underline" href={href} legacyBehavior>
      {anchorCopy}
    </Link>
  )
}
